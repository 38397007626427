@import "~mdbreact/dist/scss/core/variables";

// html
html {
  overflow-x: hidden;
  min-height: 100%;
  margin: 0;
}

// body
body {
  overflow-y: $only-scroll-if-needed;
  overflow-x: $no-horizontal-scroll;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.modal-open {
    overflow-y: $only-scroll-if-needed;
    overflow-x: $no-horizontal-scroll;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.span-mouse {
  cursor: pointer;
}

table {
  &.table {
    td {
      .header {
        height: 20px;

        .avatar {
          width: 80px;
          height: 100%;
          position: relative;
          margin: 0 auto;

          img {
            display: block;
            border-radius: 50%;
            position: absolute;
            bottom: -36px;
            border: 4px solid white;
            width: 72px;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          cursor: pointer;
        }

        &:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, 0.05);
        }

        .dataTable & {
          height: 76px;
        }
      }
    }

    thead:last-child {
      display: none;
    }
  }
}

// theme
.light-theme {
  background-color: #f1f4f6;

  &.metrics {
    background-color: #ededee;
  }
}

.sky-theme {
  background-color: #e3f2fd;
}

// containers
.container-fluid {
  &.inner {
    padding-bottom: 1.5rem;
  }

  &.hq-login {
    padding-left: 0rem;
    padding-right: 0rem;

    .card {
      &.reverse {
        padding-bottom: 8.25rem;
        box-shadow: none;
      }
    }

    .main-image {
      height: 24rem;
      width: 110%;
    }
  }
}

section {
  &.stacks-section{
    .stack-col{
      width: 2px;
     }

     .proj-col{
       width: 267px;
     }

     .proj-name{
       padding-top: 3.5rem;
     }

     @media (min-width: 576px){
       .jumbotron {
           padding: 1rem 2rem;
       }
     }
     
     .table-responsive {
       overflow-x: hidden !important;
     }
  }

  &.hq-section {
    padding-top: 3rem;

    &.hq-main {
      background-color: #e9ebee;

      > .row {
        > [class*='col-'] {
          @media (max-width: 575.98px) {
            &:nth-of-type(2n) {
              order: 2;
            }

            &:nth-of-type(3n) {
              order: 1;
            }

            &:nth-of-type(4n) {
              order: 3;
            }
          }
        }
      }
    }

    &.hq-about {
      margin-top: 1rem;
      margin-bottom: 0.25rem;
      padding-top: 1rem;

      > .row {
        &.main {
          > [class*="col-"]:first-of-type {
            margin-right: 2.25rem;
          }
        }
      }

      .page-table {
        width: 95%;

        .add-user {
          margin-bottom: 1rem;
        }

        > .row:nth-of-type(2) {
          margin-bottom: 0.5rem;
        }

        .table-title {
          padding-top: 1rem;
        }

        .user-table {
          padding-top: 15px;
          padding-left: 15px;
          padding-right: 15px;
          background-color: #fff;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        
          table {
            &.table {
              tr {
                td:nth-child(2) {
                  padding-top: 1.75rem;
                  padding-bottom: 2.85rem;
                }
              }
            }
          }
        }
      }
    }

    &.hq-profile {
      padding-bottom: 3rem;

      > .row {
        &.main {
          > [class*="col-"]:first-of-type {
            margin-right: 0.75rem;
          }
        }
      }
    }

    &.hq-add-user {
      margin-top: 0rem;
    }

    @media (max-width: $small-screen-up) {
      padding-top: 2.25rem;
    }

    @media (max-width: 575.98px) {
      padding-top: 1.25rem;
    }

    &.hr-page {
      > .row {
        &.main {
          > [class*="col-"]:first-of-type {
            margin-right: 1.75rem;
          }
        }
      }
    }

    .hq-content-1 {
      margin-right: 1.65rem;

      &.hq-profile {
        margin-right: 0.75rem;

        > .row {
          [class*="col-"]:nth-of-type(2) {
            padding-left: 1rem;
          }
        }
      }

      &.hq-add-user {
        > .row {
          [class*="col-"] {
            padding-left: 3.25rem;
          }
        }
      }

      > .row {
        @media (max-width: $small-screen-up) {
          margin-left: 2%;
        }

        [class*="col-"]:nth-of-type(2) {
          padding-left: 0rem;

          @media (max-width: $small-screen-up) {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 0.75rem;
          }

          @media (max-width: 575.98px) {
            padding-left: 10.5px;
            padding-right: 0px;
          }

          > h5 {
            margin-bottom: 1rem;

            @media (max-width: $small-screen-up) {
              font-size: 1rem;
              margin-bottom: 0.5rem;
            }
          }

          p {
            &.grey-text {
              @media (max-width: $small-screen-up) {
                margin-bottom: 0rem;
              }
            }
          }
        }
      }

      &.hr-page {
        width: 16rem;
        margin-right: 0rem;

        .header {
          height: 20px;
        }

        @media (max-width: $mobile-screen-up) {
          display: none;
        }

        > .row {
          [class*="col-"]:first-child {
            @media (max-width: $small-screen-up) {
              margin-left: 30px;
            }
          }
        }
      }
    }

    .hq-content-2 {
      padding-left: 0.5rem;
      padding-right: 2.5rem;

      .card {
        &.cascading-admin-card {
          .updater {
            padding-left: 6px;
            font-size: 0.75rem;

            @media (max-width: 575.98px) {
              font-size: .65rem;
            }
          }
        }
      }

      &.hq-profile {
        margin-right: 0rem;
        padding-left: 0rem;

        .nav-tabs {
          a {
            &.nav-link {
              font-weight: 400;
              padding-left: 0.9rem;
              padding-right: 0.9rem;
            }
          }
        }

        .tab-content {
          padding-top: 0.325rem;
          padding-left: 15px;
          padding-right: 15px;
          background-color: #fff;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);

          > .row {
            &:first-child {
              [class*="col-"] {
                &:first-child {
                  h5 {
                    margin-top: 0.625rem;
                  }
                }
              }
            }

            &:nth-of-type(3n) {
              margin-top: 16.25px;
              margin-bottom: 3.5px;
            }
          }

          h5 {
            @media (max-width: $small-screen-up) {
              padding-left: 0.25rem;
            }
          }

          hr {
            margin-top: 0.25rem;
          }

          p {
            $textcolor: rgb(30, 30, 212);
            color: $textcolor;
            margin-top: .5rem;
            margin-bottom: .25rem;

            &.form-title {
              &[class*="hq-add-"] {
                display: inline-flex;
              }

              &.hq-add-user {
                margin-right: 27.25%;
              }

              &.hq-add-detail {
                margin-right: 19.25%;
              }
            }
          }

          .social-media {
            width: 38px;
            float: left;
          }

          .edit-button,
          .action-btn,
          .leave-button {
            height: 36px;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            margin-right: 0rem;
          }

          .datepick,
          .datepick2 {
            width: 100%;
            border: none;
            outline: 0;
            border-bottom: 1px solid #ced4da;
          }

          .dob {
            color: #757575;
            font-size: .825rem;
          }

          .react-datepicker__input-container {
            top: 2px;
          }

          .react-datepicker-popper[data-placement^="top"] {
            top: 12px !important;
          }

          .manager-select {
            [class*="css-"]:first-child {
              border-top-style: none;
              border-right-style: none;
              border-left-style: none;
              border-radius: 0px;
              cursor: pointer;
              background-color: #fff;
            }

            [class*="css-"]:hover {
              border-top-style: none;
              border-right-style: none;
              border-left-style: none;
              border-radius: 0px;
              cursor: pointer;
              border-color: #ced4da;
              box-shadow: none;
            }
          }

          .css-2b097c-container {
            padding-top: -20px;
          }
        }

        > .row {
          [class*="col-"] {
            @media (max-width: $small-screen) {
              padding-left: 30px;
              padding-right: 30px;
            }
          }

          [class*="col-"]:nth-of-type(2) {
            @media (max-width: $small-screen) {
              padding-left: 30px;
              padding-right: 30px;
            }
          }

          &:nth-of-type(1) {
            [class*="col-"] {
              flex: 0 0 93.666667%;
              max-width: 93.666667%;
            }
          }

          &:nth-of-type(2) {
            padding-left: 1rem;
          }

          &:nth-of-type(1) {
            [class*="col-"] {
              flex: 0 0 93.666667%;
              max-width: 93.666667%;
            }
          }

          &:nth-of-type(2) {
            padding-left: 1rem;
          }
        }
      }

      @media (max-width: $small-screen-up) {
        padding-left: 0rem;
      }

      > .row {
        @media (max-width: $small-screen-up) {
          margin-left: 2%;
        }

        [class*="col-"]:nth-of-type(2) {
          @media (max-width: $small-screen-up) {
            padding-left: 42px;
            padding-right: 30px;
            padding-bottom: 0.75rem;
          }

          @media (max-width: 575.98px) {
            padding-left: 22px;
            padding-right: 4px;
            padding-bottom: 0rem;
          }

          > h5 {
            margin-bottom: 1rem;

            @media (max-width: $small-screen-up) {
              font-size: 1rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }

      &.hr-page {
        padding-left: 0rem;

        .nav-tabs {
          @media (max-width: $mobile-screen-up) {
            width: 80%;
            margin-left: 42px;
          }

          a {
            &.nav-link {
              font-weight: 400;
            }
          }
        }

        .tab-content {
          padding-left: 15px;
          padding-right: 15px;
          background-color: #fff;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);

          @media (max-width: $mobile-screen-up) {
            width: 80%;
            margin-left: 42px;
          }
        }
      }
    }
  }

  &.hq-metricview {
    > [class*="col-"] {
      display: inline-grid;

      &:not(:last-child) {
        padding-right: 0rem;
      }
    }

    .metric-badge {
      font-size: 100% !important;
      line-height: 2 !important;
    }

    .detailed-cards {
      > [class*="col-"] {
        padding: 0.5rem;
      }

      .cascading-admin-card {
        margin-top: 0.5rem !important;

        .card-body {
          padding: 0.5rem;

          .nav-link {
            padding: 0rem;
            min-height: 120px;

            > .row {
              &:first-child {
                margin-bottom: 0.75rem;
              }
            }
          }

          hr {
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;
          }

          > .row {
            &.empty {
              margin-bottom: 2.1rem;
            }

            &:not(:first-child) {
              p {
                font-size: 0.75rem;
              }
            }

            &:last-child {
              .data-details {
                float: left;
                text-align: left;

                .updater {
                  padding-left: 1rem;
                }
              }
            }
          }
        }

        .icon-shape {
          padding: 12px;
          text-align: center;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          i {
            font-size: 1.25rem;
          }
        }

        .shadow {
          box-shadow: $box-shadow !important;
        }

        .data-details {
          float: right;
          text-align: right;
        }

        .texts-aligns {
          margin-top: -12px !important;
        }

        .icon-details {
          margin: 0rem;
        }
      }
    }
  }
}

// displays on main HQ page
.hq-card {
  width: $card-width-small;

  .hq-content-1.hq-profile & {
    width: 16rem;

    .header {
      height: 20px;

      .avatar {
        width: 132px;
        height: 100%;
        position: relative;
        margin: 0 auto;

        img {
          display: block;
          border-radius: 50%;
          position: absolute;
          bottom: -42px;
          border: 4px solid white;
          width: 130px;
        }
      }
    }

    .img-fluid {
      @media (min-width: 321px) and (max-width: $small-screen-up) {
        margin-top: -135px;
        height: 288px;
      }
    }
  }

  @media (max-width: $small-screen-up) {
    width: 94%;
  }

  .card-body {
    padding-top: 3.25rem;

    .hq-main & {
      padding-top: 0.85rem;
      padding-bottom: 0.85rem;
    }

    @media (max-width: $small-screen-up) {
      padding-top: 3.25rem;
      padding-bottom: 0.65rem;
    }

    h4 {
      &.card-title {
        @media (max-width: $small-screen-up) {
          font-size: 1rem;
          margin-bottom: 0.125rem;
        }
      }
    }

    p {
      &.card-text {
        @media (max-width: $small-screen-up) {
          font-size: 0.875rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .btn {
      padding: 0.5rem 1.14rem;
      margin-left: 0rem;

      @media (max-width: $small-screen-up) {
        font-size: 0.725rem;
        padding: 0.35rem 1.14rem;
        margin-left: 0rem;
      }
    }
  }

  .img-fluid {
    @media (min-width: 321px) and (max-width: $small-screen-up) {
      margin-top: -135px;
    }

    @media (max-width: 320px) {
      margin-top: -40px;
    }
  }
}

// displays on HRiS main page
.hr-card {
  &.card-body {
    padding: 1.0625rem;

    @media (max-width: $small-screen-up) {
      padding: 10px;
    }

    @media (max-width: $mobile-screen-up) {
      margin-left: 42.5px;
      width: 80%;
    }

    > .row:not(:first-child) {
      [class*="col"]:nth-of-type(1) {
        padding-top: 0.5rem;
      }

      button {
        margin-top: 0rem;
      }
    }

    > .row:not(:last-child) {
      [class*="col"]:nth-of-type(1) {
        padding-bottom: 0.5rem;
      }

      button:not(:first-child) {
        @media (max-width: $mobile-screen-up) {
          margin-top: 0rem;
        }
      }
    }

    > .row {
      .btn {
        &:first-of-type {
          @media (max-width: $small-screen-up) {
            margin-left: 0.8rem;
          }
        }
      }

      &:nth-of-type(3) {
        @media (max-width: $small-screen-up) {
          display: none;
        }
      }
    }

    &.main {
      // Adjust this height for proper balance
      height: 190px;

      @media (max-width: $small-screen-up) {
        height: 160px;
      }
    }

    &.block {
      padding-top: 0.0625rem;
      padding-bottom: 0.075rem;

      section.hq-section.hr-page & {
        > .row {
          [class*="col-"] {
            padding: 1rem;

            @media (max-width: $small-screen-up) {
              padding: 0.15625rem;
            }
          }

          button:not(:first-child) {
            margin-left: 0rem;

            @media (max-width: $mobile-screen-up) {
              margin-left: 0.8rem;
            }
          }

          .btn {
            width: 32%;

            @media (max-width: $small-screen-up) {
              width: 30%;
            }

            @media (max-width: $mobile-screen-up) {
              width: 92%;
            }

            &.btn-default {
              text-transform: none;
              text-align: left;
              padding: 0rem;

              a {
                color: #fff;
              }
            }

            .nav-link:hover,
            .nav-link:focus {
              color: rgba(255, 255, 255, 0.75);
            }

            .fa-4x {
              font-size: 3em !important;

              @media (max-width: $mobile-screen-up) {
                font-size: 2rem !important;
              }
            }
          }
        }

        > .row:nth-of-type(1) {
          .btn-default:first-child {
            background-color: #348525 !important;
          }

          .btn-default:nth-child(2) {
            background-color: #00b0e6 !important;
          }

          .btn-default:last-child {
            background-color: #132034 !important;
          }
        }

        > .row:nth-of-type(2) {
          .btn-default:first-child {
            background-color: #322254 !important;
          }

          .btn-default:nth-child(2) {
            background-color: #e53b3a !important;
          }

          .btn-default:last-child {
            background-color: #348525 !important;
          }
        }
      }

      > .row {
        [class*="col"]:first-child {
          border: none;
          height: 184px;

          @media (max-width: $small-screen-up) {
            height: 120px;
          }

          @media (max-width: $mobile-screen-up) {
            height: 30px;
          }
        }
      }
    }

    a {
      &.nav-link {
        @media (max-width: $small-screen-up) {
          padding: 0.25rem 0.5rem;
        }

        h4 {
          &.card-title {
            @media (max-width: $mobile-screen-up) {
              display: inline-block;
              vertical-align: top;
            }

            @media (max-width: $small-screen-up) {
              font-size: 1.25rem;
            }

            span {
              display: block;

              @media (max-width: $mobile-screen-up) {
                display: inline-block;
              }
            }
          }
        }

        .card-text {
          @media (max-width: $mobile-screen-up) {
            display: none;
          }
        }
      }
    }
  }

  .hq-content-1.hr-page & {
    width: 16rem;

    @media (max-width: $small-screen-up) {
      width: 10rem;
    }
  }
}

.hq-login {
  .card-body {
    height: 33.5rem;

    @media (max-width: $small-screen-up) {
      height: 32rem;
    }

    @media (max-width: $mobile-screen-up) {
      height: 28rem;
    }

    h4 {
      &.main-message {
        font-weight: 500;

        @media (max-width: $mobile-screen-up) {
          font-weight: inherit;
        }
      }
    }

    .help-text {
      margin-top: 0.5rem;
      .nav-link {
        display: inline;
      }

      @media (max-width: $small-screen-up) {
        font-size: 0.75rem;
      }
    }

    .end-text {
      padding-top: 12.85rem;

      @media (max-width: $small-screen-up) {
        font-size: 0.75rem;
      }
    }
    &.hq-auth {
      .end-text {
        padding-top: 10.85rem;
      }
    }
  }

  .main-logo {
    font-size: 5rem;
    padding-top: 3rem;
    margin-bottom: -0.5rem;

    @media (max-width: $small-screen-up) {
      font-size: 4rem;
    }

    @media (max-width: $mobile-screen-up) {
      font-size: 3rem;
    }
  }

  .main-message {
    font-style: oblique;
    padding-left: 1.5rem;
    margin-bottom: 10rem;

    @media (max-width: $small-screen-up) {
      margin-bottom: 7rem;
      font-size: 1.35rem;
    }

    @media (max-width: $mobile-screen-up) {
      font-size: 1rem;
    }
  }

  .hq-auth {
    .main-message {
      margin-bottom: 5rem;
    }
  }

  .google-login {
    width: 200px;
    height: 50px;
    font-size: 1rem !important;

    @media (max-width: $small-screen-up) {
      width: 192px;
      height: 46px;
    }

    @media (max-width: $mobile-screen-up) {
      width: 164px;
      height: 36px;
      font-size: 0.875rem !important;
    }

    div:first-child {
      padding: 12px !important;

      @media (max-width: $small-screen-up) {
        padding: 10px !important;
      }

      @media (max-width: $mobile-screen-up) {
        padding: 6px !important;
        margin-left: 0.25px;
      }
    }

    span {
      @media (max-width: $mobile-screen-up) {
        padding: 7.25px 7.25px 7.25px 0px !important;
      }
    }
  }
}

.modal-dialog {
  &.hq-add-user {
    @media (min-width: $medium-screen-up) {
      max-width: 38%;
    }

    @media (max-width: $medium-screen) {
      max-width: 45%;
    }

    @media (max-width: 767.98px) {
      max-width: 56%;
    }

    @media (max-width: $small-screen) {
      max-width: 60%;
    }

    @media (max-width: 575.98px) {
      max-width: 90%;
      margin: 2.75rem auto;
    }

    .modal-body {
      @media (max-width: 575.98px) {
        padding: 1rem;
      }

      &.tab-content {
        padding-top: 1rem;

        @media (max-width: 575.98px) {
          padding-top: 0.5rem;
        }
        
        .manager-select {
          [class*="css-"]:first-child {
            border-top-style: none;
            border-right-style: none;
            border-left-style: none;
            border-radius: 0px;
            cursor: pointer;
            background-color: #fff;
          }

          [class*="css-"]:hover {
            border-top-style: none;
            border-right-style: none;
            border-left-style: none;
            border-radius: 0px;
            cursor: pointer;
            border-color: #ced4da;
            box-shadow: none;
          }
        }

        .row {
          > [class*="col-sm-"] {
            @media (max-width: 575px) {
              max-width: 50%;
            }

            &:first-child {
              @media (max-width: 575px) {
                padding-right: 7.5px;
              }
            }

            &:last-child {
              @media (max-width: 575px) {
                padding-left: 7.5px;
              }
            }
          }

          &:nth-child(4) {
            .md-form {
              @media (max-width: 575px) {
                margin-top: 0.5rem;
              }
            }
          }
        }

        span {
          &.invalid-feedback {
            display: block !important;
            margin-top: -1.5rem;
            margin-bottom: 0.25rem;

            @media (max-width: 575.98px) {
              font-size: 0.725rem;
            }
          }
        }

        .md-form {
          margin-top: 0.65rem;

          &:first-child {
            @media (max-width: 575.98px) {
              margin-top: 0.95rem;
            }
          }

          &.form-sm {
            .validate {
              margin-bottom: 1.6rem;
            }

            label {
              @media (max-width: 767.98px) {
                font-size: 0.825rem;
              }

              @media (max-width: 575.98px) {
                font-size: 0.75rem;
                margin-bottom: 0.125rem;
              }
            }
          }

          .form-control {
            &.is-invalid {
              margin: 0rem;
            }
          }
        }

        .form-group {
          display: inline-flex;
          margin-bottom: 0.5rem;

          &.selector {
            width: 100%;

            fieldset {
              width: 100%;
            }

            .row {
              [class*="col-"] {
                &:nth-of-type(2) {
                  margin-left: -1.5rem;

                  @media (max-width: 381.98px) {
                    margin-left: -0.5rem;
                  }
                }
              }
            }

            span {
              &.invalid-feedback {
                margin-top: 0.15rem;
              }
            }
          }

          legend {
            color: #757575;
            font-size: 0.875rem;

            @media (max-width: 575px) {
              font-size: 0.75rem;
            }
          }

          .form-check {
            padding-left: 0.35rem;

            &.my-3 {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }

            .form-check-label {
              &.mr-5 {
                margin-right: 0 !important;
                margin-left: 1.725rem;

                @media (max-width: 767.98px) {
                  margin-left: 0.895rem;
                }

                @media (max-width: 575.98px) {
                  font-size: 0.875rem;
                  margin-left: 2.875rem;
                }

                @media (max-width: 449.98px) {
                  margin-left: 1rem;
                }

                @media (max-width: 399.98px) {
                  margin-left: 1.325rem;
                }

                @media (max-width: 381.98px) {
                  margin-left: 0.975rem;
                }

                @media (max-width: 319.98px) {
                  margin-left: 0.85rem;
                }
              }
            }

            .form-check-input {
              right: 2.25rem;
              margin-right: 0rem;

              @media (max-width: 767.98px) {
                right: 1.25rem;
              }

              @media (max-width: 399.98px) {
                right: 1.5rem;
              }

              @media (max-width: 381.98px) {
                right: 1.125rem;
                margin-left: -1rem;
              }

              @media (max-width: 319.98px) {
                right: 1rem;
              }
            }
          }

          .form-control {
            &.radio-button {
              font-size: 0.25rem;
              height: calc(1.025em + 0.65rem + 1px);
              width: 80%;

              @media (max-width: 767.98px) {
                height: calc(1.025em + 0.5rem + 0.5px);
                width: 100%;
              }
            }
          }

          input[type="radio"] {
            margin-top: 0.25rem;
            margin-right: 0.25rem;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }

        .datepick {
          color: #757575;
          font-size: 0.875rem;

          @media (max-width: 575px) {
            font-size: 0.75rem;
          }
        }
      }
    }

    .modal-content {
      @media (max-width: 767.98px) {
        .modal-header {
          padding: 0.25rem 0.5rem;
        }
      }

      @media (max-width: 575.98px) {
        .modal-header {
          padding: 0.25rem 0.75rem;
        }

        .modal-title {
          font-size: 1.125rem;
        }
      }
    }

    .modal-footer {
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  &.hq-leave {
    @media (min-width: $medium-screen-up) {
      max-width: 32%;
    }

    @media (min-width: 576px) {
      margin: 3.75rem auto;
    }

    .modal-header {
      padding-top: 9.5px;
      padding-bottom: 9.5px;

      .close {
        padding: 0.25rem 0.65rem;
        margin: 0.05rem 0rem 0rem;
      }
    }

    .modal-body {
      &.tab-content {
        .md-form {
          &.md-outline {
            margin-top: 1.65rem;

            &.form-sm {
              .form-control {
                &.form-control-sm {
                  padding-top: 0.85rem;
                  padding-bottom: 3.5rem;
                  font-size: 1rem;
                  font-weight: 300;
                }
              }

              label {
                font-size: 1rem;
              }
            }
          }
        }

        .disabled {
          background-color: #e9ecef;
        }
      }
    }

    .leave-type {
      &[class$="-container"] {
        padding-top: 30px;
      }
    }
  }

  .metrics & {
    @media (min-width: 576px) {
      margin: 2.75rem auto;
      max-width: 400px;
    }

    .modal-header,
    .modal-footer {
      @media (min-width: 576px) {
        padding: 0.5rem 0.75rem;
      }

      .modal-title {
        width: 100%;
      }

      .dropdown {
        .btn {
          padding: 0.25rem 0.5rem;
        }

        .dropdown-toggle::after {
          margin-left: 0.5em;
        }
      }

      .dropdown-menu {
        padding: 0.25rem 0;
        font-size: 0.875rem;
        min-width: 7.5rem;
        transform: translate3d(-53px, 32px, 0px) !important;
      }

      .dropdown-item {
        padding: 0rem 0.5rem;
      }
    }

    .metrics-edit-content {
      padding: 0.5rem 1rem;

      > .row {
        [class*="col-"] {
          &:first-child {
            padding-right: 9px;
          }

          &:nth-child(2) {
            padding-left: 0;
          }
        }
      }

      .dropdown-setting {
        height: 45px !important;
      }

      .md-form {
        margin-top: 1.125rem;

        .form-control {
          padding: 0.6rem 0 0.25rem 0;
        }

        &.form-sm {
          label {
            &.active {
              font-size: 0.875rem;
            }
          }
        }
      }

      .empty {
        margin-bottom: 16px;
      }

      .manager-select {
        [class$="-control"] {
          border-top-style: none;
          border-right-style: none;
          border-left-style: none;
          border-radius: 0px;
          cursor: pointer;
          background-color: #fff;
        }

        .css-1pahdxg-control {
          border-top-style: none;
          border-right-style: none;
          border-left-style: none;
          border-radius: 0px;
          cursor: pointer;
          border-color: #ced4da;
          box-shadow: none;
        }

        input {
          font-size: 0.875rem !important;

          &:focus {
            color: transparent;
          }
        }

        [class$="-control"] {
          &:hover {
            border-top-style: none;
            border-right-style: none;
            border-left-style: none;
            border-radius: 0px;
            cursor: pointer;
            border-color: #ced4da;
            box-shadow: none;
          }
        }

        [class$="-placeholder"] {
          font-size: 0.875rem;
          top: 65%;
        }

        [class$="-menu"] {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.page-footer {
  padding-top: 1.5rem;

  &.font-small {
    font-size: 0.71875rem;
  }

  .row {
    h5 {
      @media (max-width: $small-screen-up) {
        font-size: 1rem;
      }
    }

    p,
    ul {
      @media (max-width: $small-screen-up) {
        font-size: 0.75rem;
      }
    }

    [class*="col-"]:nth-of-type(3) {
      h5 {
        &.title {
          padding-left: 2.5rem;

          @media (max-width: $small-screen-up) {
            padding-left: 0rem;
          }
        }
      }

      ul {
        @media (max-width: $small-screen-up) {
          padding-left: 0rem;
        }
      }
    }
  }

  .footer-copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media (max-width: $small-screen-up) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.metrics {

  .hq-content-2  & {
    section.hq-section & {
      .row {
        [class*='col-'] {
          &:nth-of-type(2n) {
            @media (max-width: $small-screen-up) {
              padding-left: 15px;
              padding-right: 15px;
            }
          }
        }

      }
    }
  }


  .flexible-content {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #ededee;

    @media (max-width: 1199.98px) {
      padding-left: 0;
    }

    main {
      padding: 0 72px 0 0;
    }

    span {
      &.updates {
        padding-left: 4px;
      }
    }
  }

  .card {
    &.cascading-admin-card {
      .card-body {
        padding: 0.5rem 1.25rem 0.75rem;
        font-size: .75rem;

        .progress {
          @media (max-width: 575.98px) {
            height: .75rem !important;
            font-size: .65rem;
          }
        }

        .card-text {
          padding-top: 4px;
        }
      }

      @media (max-width: 1199.98px) {
        margin-top: 2.5rem;
      }

      @media (min-width: 1200px) {
        margin-top: 2.5rem;
      }
    }
  }

  .row {
    &:first-child {
      [class*="col-"] {
        &:first-child,
        &:nth-child(2) {
          .card {
            &.cascading-admin-card {
              margin-top: 1.25rem;

              @media (max-width: 575.98px) {
                margin-top: 2.5rem;
              }
            }
          }
        }
      }
    }
  }

  .cascading-admin-card {
    .admin-up {
      margin-left: 4%;
      margin-right: 4%;
      margin-top: -20px;

      .fa {
        padding: 1.7rem;
        font-size: 2rem;
        color: #fff;
        text-align: left;
        margin-right: 1rem;
        border-radius: 3px;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2),
          0 2px 13px 0 rgba(0, 0, 0, 0.19);

        @media (max-width: 575.98px) {
          padding: .75rem;
        }
      }

      .data {
        float: right;
        margin-top: 2rem;
        text-align: right;

        p {
          color: #999999;
          font-size: 12px;

          @media (max-width: 575.98px) {
            font-size: 10px;
            margin-bottom: .125rem !important;
          }
        }

        h4 {
          @media (max-width: 575.98px) {
            font-size: 1.25rem;
            margin-bottom: .0125rem;
          }
        }
      }
    }
  }

  &.nav-link {
    padding: 0rem;

    > [class*="col-"] {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.hq-404 {
  .full {
    height: 80vh;
  }

  .hq-about & {
    .full {
      height: 54.5vh;
    }

    .metrics & {
      .full {
        height: 46.85vh;
      }
    }
  }

  .bad-gateway-row {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .metrics & {
    .bad-gateway-row {
      left: 45%;
    }
  }

  .nav-link {
    display: inline-flex;
    padding: 0.5rem 0.5rem 0rem;
  }
}

.react-datepicker-wrapper {
  margin-bottom: 1.5rem;
  width: 100%;

  input[type="text"] {
    width: 100%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    box-sizing: content-box;
    background-color: transparent;
    color: #495057;
    font-size: 0.875rem;
    padding: 0.35rem 0 0.4rem 0;

    @media (max-width: 575px) {
      font-size: 0.75rem;
    }

    &:focus {
      color: #495057;
      background-color: #fff;
      border: none;
      border-bottom: 1.5px solid #007bff;
      outline: 0;
    }

    &::placeholder {
      color: #757575;
      font-size: 0.875rem;
      font-weight: 200;
    }
  }

  .hq-add-user & {
    input[type="text"] {
      @media (max-width: 575px) {
        font-size: 0.75rem;
      }
    }
  }

  .hq-leave & {
    font-size: 1rem;
    margin-top: 30px;

    input[type="text"] {
      padding: 0.35rem 0 0.4rem 0.75rem;

      &::placeholder {
        font-size: 1rem;
        color: #757575;
        font-weight: 300;
      }
    }

    .react-datepicker__input-container {
      padding-right: 0.75rem;
    }
  }
}

.leave-point {
  .hq-content-2.hq-profile & {
    .tab-content {
      > .row {
        &:first-child {
          [class*="col-"] {
            &:first-child {
              h5 {
                margin-top: 0.825rem;
                margin-bottom: 1.15rem;
              }
            }
          }
        }
      }

      &.leave-requests {
        padding-bottom: 2rem;

        h6 {
          &:first-child {
            margin-top: 0.5rem;
            margin-bottom: 0.75rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.hq-approve-leave {

  &.row {
    padding-left: 1rem;
  }

  .tab-content {
    padding-top: .325rem;

    &.leave-approval {
      padding-bottom: 2rem;
    }

    .leave-details {
      padding-right: 0px;

      h6 {
        &:nth-of-type(1n) {
          margin-bottom: .75rem;
        }
      }

      > .row {
        padding-left: 28.5px;
      }

      .btn {
        margin-left: 0rem;

        &.btn-sm {
          min-width: 100px;
          margin-top: .625rem;
        }
      }
    }
  }

  section.hr-page & {

    h5 {
      margin-top: .625rem;
      margin-bottom: 1.15rem;
    }

    .approval-requests {
      width: 100%;
      padding-bottom: 1.45rem;
      background-color: white;
      border-radius: 6px;

      .badge {
        margin-right: .5rem;
        margin-top: .5rem;
      }

      .approval-pending-img {
        display: inline-flex;

        .avatar {
          width: auto;
          height: 20px;
          margin: 0rem;

          img {
            display: block;
            border-radius: 50%;
            bottom: -36px;
            width: 42.5px;
          }
        }
      }
    }

    .profile-pic,
    .out-avater {
      .avatar {
        width: 80px;
        height: 100%;
        margin: 0 auto;

        img {
          display: block;
          border-radius: 50%;
          border: 2px solid white;
          width: 80px;
        }
      }
    }

    .approve-calendar {

      .calp-date-title {
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        border: 1px solid #673ab7;
        border-radius: 4px;
        height: 80px;

        .calp-month {
          color: #fff;
          background: #673ab7;
          font-size: 0.825rem !important;
          text-transform: uppercase;
          letter-spacing: 0.125rem;
          padding: 0.125rem 0 0.125rem;
        }

        .calp-day {
          font-size: 1.75rem !important;
          padding: 0.25rem 0 0 !important;
        }

        .calp-weekday {
          font-size: 1rem !important;
          line-height: 1.5rem;
        }
      }

      .date-range {
        > .row {
          [class*='col-'] {
            &:nth-child(2n) {
              padding-left: 0rem;

              i {
                margin-top: 2rem;
                margin-left: .125rem;
              }
            }

            &:last-child {
              padding-left: 0rem;
              padding-right: 30px;
            }
          }
        }
      }
    }

    .leave-reason {
      .md-form {
        &.md-outline {
          margin-top: 2.75rem;
          margin-right: .75rem;
          margin-bottom: 0rem;
        }

        .form-control {
          min-height: 82.5px;
        }
      }
    }

    .others-on-leave {
      .row {
        h6 {
          display: inline;
        }
      }

      > div {
        margin-bottom: .5rem;
      }
    }
  }

  .leave-approval {
    .container {
      > [class*='col-'] {
        padding: 0rem;

        &:first-child {
          > .row {

            h6 {
              font-size: 1.2rem;
              margin-top: .95rem;
              margin-bottom: 1.5rem;
            }

            [class*='col-'] {
              &:nth-child(2n) {
                padding-left: 29px;
              }
            }
          }
        }

        &:nth-child(2n) {
          > .row {
            margin-bottom: 1.85rem;
          }
        }
      }

      .Cal__Container__root {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }

      .Cal__Weekdays__root {
        background-color: #e0e0e0 !important;
        color: #000 !important;
        font-weight: 400 !important;
      }

      .Cal__Day__range {
        &.Cal__Day__selected {
          &.Cal__Day__betweenRange {
            .Cal__Day__selection {
              color: #673ab7 !important;
            }
          }

          &.Cal__Day__start {
            .Cal__Day__selection {
              background-color: #673ab7 !important; 
            }
          }

          &.Cal__Day__end {
            .Cal__Day__selection {
              border-color: #673ab7 !important;
              color: #673ab7 !important;
            }
          } 
        }
      }
    }
  }

  .leave-accepted,
  .leave-denied {
    .modal-header {
      padding-top: 9.5px;
      padding-bottom: 9.5px;

      .close {
        padding: 0.25rem 0.65rem;
        margin: 0.05rem 0rem 0rem;
      }
    }

    .modal-footer {
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}