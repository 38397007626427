/*!
 * Kanzu Style  @2019
 *
 * Based on Material Design for Bootstrap 4
 * Version: 1.0.0
 */

@charset "UTF-8";

// VARIABLES
@import 'custom_variables';

// GLOBAL
@import 'globals';

// TOASTR
@import 'toastr';

// COLORS
@import 'colors';

// CORE
@import 'core/navbars';
@import 'core/carousels';

// THIRD-PARTY
@import '3pp';
