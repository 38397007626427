
// ===========================================================================
// KC variables
// ===========================================================================
//
// Table of Contents: 
//
//  1. Fonts
//  2. Shadows
//  3. Transitions
//  4. Borders
//  5. Globals
//  6. Masks
//  7. Images
//  8. Buttons
//  9. Cards
//  10. Navbars
//  11. Pagination
//  12. Badges
//  13. Modals
//  14. Carousels
//  15. Forms
//  16. Miscellaneous
//  17. Footers
//  18. List groups
//  19. Tables
//  20. Steppers

@import '~mdbreact/dist/scss/core/colors';



// 1. Fonts
// ===========================================================================

// Icons
$kc-blue                                   : $blue-base; 


// 5. Globals
// ===========================================================================

// Scroll
$no-space                                  : 0rem !default;
$only-scroll-if-needed                     : auto;
$no-horizontal-scroll                      : hidden;
$no-vertical-scroll                        : $no-horizontal-scroll;
$mobile-screen-up                          : 321px;


// 10. Cards
// ===========================================================================
   
// Cards
$card-width                                : 22rem !default;
$card-width-small                           : 18.125rem;  

// 10. Navbars
// ===========================================================================

// Top-Navbar
$top-index                                 : 1001;
$navbar-spacer-x                           : 6.85rem !default;
$navbar-spacer-x-small                     : 0.825rem !default; 
$navbar-spacer-y                           : $no-space;
$dropdown-min-width                        : 9rem !default;


// 14. Carousels
// ===========================================================================

// Main-Carousel
$carousel-control-icon-width               : 2.25rem !default;
$carousel-control-icon-height              : $carousel-control-icon-width !default; 
$carousel-control-icon-margin-top          : 11.25rem !default;       
$carousel-large-space                      : 2.5rem !default;
$carousel-no-space                         : $no-space; 
$negative-215                              : -215px;

$carousel-indicators-bottom                : $carousel-large-space / 10;
$carousel-size                             : $carousel-large-space / 100 + 0.5;
$carousel-caption-padding-small            : 0.15625rem;  


// 16. Miscellaneous
// ===========================================================================
$gray-600: #8898aa !default;
$box-shadow: 0 0 2rem 0 rgba($gray-600, 0.15) !default;

// General Section