// Carousels
.carousel {
  margin-top: $negative-215;

  @media (max-width: $small-screen-up) {
    margin-top: $carousel-no-space;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    @media (min-width: $small-screen-up) {
      width: $carousel-control-icon-width;
      height: $carousel-control-icon-height;
      margin-top: $carousel-control-icon-margin-top;
    }
  }

  .carousel-control-prev-icon {
    @media (min-width: $small-screen-up) {
      margin-left: $carousel-large-space;
    }
  }
  .carousel-control-next-icon {
    @media (min-width: $small-screen-up) {
      margin-right: $carousel-large-space;
    }
  }
  
  ol.carousel-indicators {
    @media (max-width: $small-screen-up) { 
      margin-bottom: $carousel-indicators-bottom;
    }

    li {
      @media (max-width: $small-screen-up) {
        width: $carousel-size;
        height: $carousel-size;
        margin-left: $carousel-indicators-bottom;
        margin-right: $carousel-indicators-bottom;
      } 
    }     
  }

  .carousel-caption {
    @media (max-width: $small-screen-up) {
      padding-top: $carousel-caption-padding-small;
      padding-bottom: $carousel-caption-padding-small;
    }

    .h1-responsive {
      @media (max-width: $small-screen-up) {
        font-size: 140%;
      }      
    }

    h5 {
      @media (max-width: $small-screen-up) {
        font-size: 1rem;
      }    
    }
  }
}
