// Navbars
.navbar {
  padding: $navbar-spacer-y $navbar-spacer-x;
  width: inherit;
  z-index: $top-index;

  &.navbar-dark {
    .navbar-nav {
      .nav-item {
        .dropdown-menu {
          .nav-link {
            color: #000000;
            letter-spacing: 0.03125rem;
            padding-top: 0.28125rem;
            padding-bottom: 0.28125rem;

          }
        }
      }
    }
  }

  @media (max-width: $small-screen-up) {
    padding-left: $navbar-spacer-x-small;
    padding-right: $navbar-spacer-x-small;
  }

  .dropdown-menu {
    min-width: $dropdown-min-width;
    padding: 0.25rem 0;

    button {
      &.dropdown-item {
        padding: 0rem;
      }
    }

    a {
      padding: 0.0625rem;
    }

  }

  .navbar-toggler {
    @media (max-width: $small-screen) {
      padding-left: $no-space;
      padding-right: $no-space;
    }    
  }

  .dropdown-toggle::after {
    margin-left: 0.555rem;
    vertical-align: 0.155rem;
  }
}
